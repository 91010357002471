import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/throw';
import { environment } from '../../../environments/environment';

@Injectable()
export class TiposComunicadoService {
  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  obterTiposComunicado(): Observable<any> {
    return this.http.get(`${this.url}/tiposComunicado`).map(data => data);
  }  
}
