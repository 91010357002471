import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpfCnpj'
})

export class CpfCnpjPipe implements PipeTransform {
  transform(value: any): any {

    if (value && value.length === 11) {
      return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 3)}-${value.substr(9, 2)}`
    } else if(value && value.length < 11) {
      while(value.length !== 11) {
        value = '0' + value;
      }
      return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 3)}-${value.substr(9, 2)}`
    } else if (value && value.length === 14) {
      return `${value.substr(0, 2)}.${value.substr(2, 3)}.${value.substr(5, 3)}/${value.substr(8, 4)}-${value.substr(12, 2)}`;
    } else if (value && value.length < 14) {
      while(value.length !== 14) {
        value = '0' + value;
      }
      return `${value.substr(0, 2)}.${value.substr(2, 3)}.${value.substr(5, 3)}/${value.substr(8, 4)}-${value.substr(12, 2)}`;
    }

    return value;
  }
}