import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

/**
 * Configurações para injeção na inicialização do i18n
 * TODO: criar interface nas libs para padronizar a criação de classes de configuração como esta
 */
@Injectable()
export class I18nConfiguration {
  get defaultLanguage() {
    return environment.i18n;
  }
  get languages() {
    return ['pt_br'];
  }
  get useLanguage() {
    return environment.i18n;
  }

  get optionsRegex() {
    return /en|fr/;
  }
}
