import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtesaocaetanodosul.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3548807',
  schema: 'pbi_dte_scs',
  groupId: '0f9331d4-b05c-40eb-8b60-979a21992eea',
  analiseGeral: '491946fa-d7d1-4d04-8d49-0fcd8d7c8e3c',
  analiseUsuarios: '4302e9f3-54b5-4da4-9fcf-72428bef2888',
  analiseComunicados: 'b2055805-092b-4589-94a8-b0d432fb3b43',
  analiseProcurador: '705d1fd6-c5f1-4055-966b-8eeacaf0db43',
  analiseEventos: '4b163daf-ced8-4b0b-a98b-3115fa94d9d9'
};
