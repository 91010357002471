import { Injectable } from '@angular/core';
import { TreeviewSelection, TreeviewI18n } from 'ngx-treeview';

@Injectable()
export class DefaultTreeviewI18n extends TreeviewI18n {
  constructor() {
    super();
  }

  getText(selection: TreeviewSelection): string {
    if (selection.uncheckedItems.length === 0) {
      return 'Todos';
    }

    switch (selection.checkedItems.length) {
      case 0:
        return 'Selecione as opções';
      case 1:
        return selection.checkedItems[0].text;
      default:
        return 'Opções selecionadas';
    }
  }

  getAllCheckboxText(): string {
    return 'Todos';
  }

  getFilterPlaceholder(): string {
    return 'Filtrar';
  }

  getFilterNoItemsFoundText(): string {
    return 'Não foram encontrados itens';
  }

  getTooltipCollapseExpandText(isCollapse: boolean): string {
    return isCollapse ? 'Expandir' : 'Retrair';
  }
}
