import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-detalhes-leitura',
  templateUrl: './detalhes-leitura.component.html',
  styleUrls: ['./detalhes-leitura.component.scss']
})
export class DetalhesLeituraComponent implements OnInit {
  @Output() onClosePopUp: EventEmitter<any> = new EventEmitter<any>();
  @Input() data: Object = {};

  loading: boolean = false;

  constructor() {}

  ngOnInit() {
    console.log('dadosLeitura', this.data);
  }

  fecharPopUp() {
    this.onClosePopUp.emit(false);
  }
}
